import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      firstName: '',
      lastName: '',
      email: null
    },
  },
  mutations: {
    setuserdata (state, userobject) {
      state.user.firstName = userobject.firstName
      state.user.lastName = userobject.lastName
      state.user.email = userobject.email
    },
    setuserloggedout (state) {
      state.user.email = null
      state.user.firstName = ''
      state.user.lastName = ''
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
  },
  strict: process.env.NODE_ENV !== 'production'
})
