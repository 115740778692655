import $ from "jquery";

export function getData(url, cb){

  $.ajax({

    url: '/api/' + url + '.php',
    type: 'get',
    dataType: 'JSON',
    success: function(response){

      if (response.success == true) {

        cb(null, response.data)

      } else if ("error" in response) {

        cb(new Error(response.error))

      } else {

        cb(new Error('Comms error.'))

      }

    },
    error: function() {

      cb(new Error('Comms error.'))

    }

  });

}

export function postData(url, data, cb) {

  $.ajax({
      type: 'post',
      url: '/api/' + url + '.php',
      data: JSON.stringify(data),
      contentType: "application/json",
      dataType: 'JSON',
      success: function(response){

        if (response.success == true) {

          cb(null, response.data)

        } else if ("error" in response) {

          cb(new Error(response.error))

        } else {

          cb(new Error('Comms error.'))

        }

      },
      error: function() {

        cb(new Error('Comms error.'))

      }

  });

}
