import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import UserLogin from '../views/user/Login.vue'
import UserLogout from '../views/user/Logout.vue'
import Customers from '../views/Customers.vue'
import Installations from '../views/Installations.vue'
import Kiosks from '../views/Kiosks.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/user/login',
    name: 'Login',
    component: UserLogin
  },
  {
    path: '/user/logout',
    name: 'Logout',
    component: UserLogout
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/installations',
    name: 'Installations',
    component: Installations
  },
  {
    path: '/kiosks',
    name: 'Kiosks',
    component: Kiosks
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
