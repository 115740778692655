<template>
  <div class="login">
    <br>
    <h1>{{ $t('account.logout.title') }}</h1>
    <div v-if="success">
      {{ $t('account.logout.message') }}
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
  import Loading from '@/components/Loading.vue'
  import { getData } from '@/api'

  export default {
    name: 'Login',
    components: {
      Loading
    },
    data() {
      return {
        success: null,
        show: true
      }
    },
    created () {
      this.onLoad()
    },
    methods: {
      onLoad () {
        this.error = null
        this.loading = true
        getData('v1/account/logout', (err) => {
          this.loading = false
          if (err) {
            this.error = err.toString()
          } else {
            this.success = true
            // show success toast
            this.$root.$bvToast.toast(this.$t('account.logout.success'), {
              title: this.$t('account.logout.successtitle'),
              autoHideDelay: 5000,
            })
            // destroy local data
            this.$store.commit('setuserloggedout')
          }
        })
      }
    }
  }
</script>
