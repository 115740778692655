<template>
  <div class="home">
    <br>
    <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    <div v-if="success">
      <b-row>
        <b-col cols="12" md="6" lg="3" class="mt-4">
          <b-card bg-variant="primary" text-variant="white" :header="$t('dashboard.stats.installs')" class="text-center">
            <b-card-text><h1>{{ data.stats.installs }}</h1></b-card-text>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" lg="3" class="mt-4">
          <b-card bg-variant="primary" text-variant="white" :header="$t('dashboard.stats.users')" class="text-center">
            <b-card-text><h1>{{ data.stats.usersonline + ' / ' + data.stats.users }}</h1></b-card-text>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" lg="3" class="mt-4">
          <b-card bg-variant="primary" text-variant="white" :header="$t('dashboard.stats.students')" class="text-center">
            <b-card-text><h1>{{ data.stats.students }}</h1></b-card-text>
          </b-card>
        </b-col>
        <b-col cols="12" md="6" lg="3" class="mt-4">
          <b-card bg-variant="primary" text-variant="white" :header="$t('dashboard.stats.issues')" class="text-center">
            <b-card-text><h1>{{ data.stats.issues }}</h1></b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <Loading />
    </div>
    <div v-if="showmap">
      <b-card class="mt-4" no-body>
        <template #header>
          <h6 class="mb-0">{{ $t('dashboard.map') }}</h6>
        </template>
        <b-card-text>
          <GmapMap :center="{lat:50.6875128, lng:4.3224795}" :zoom="8" map-type-id="terrain" class="google-map">
            <GmapMarker :key="index" v-for="(m, index) in mapdata" :position="{lat: parseFloat(m.lat), lng: parseFloat(m.lng)}" :clickable="false" :draggable="false" :icon="m.iconUrl"/>
          </GmapMap>
        </b-card-text>
      </b-card>
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
  import Loading from '@/components/Loading.vue'
  import { getData } from '@/api'

  export default {
    name: 'Home',
    components: {
      Loading
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: this.$t('dashboard.breadcrumbs'),
            active: true
          }
        ],
        data: {},
        success: null,
        show: true,
        showmap: null,
        mapdata: true,
      }
    },
    created () {
      this.onLoad()
    },
    methods: {
      onLoad () {
        this.error = null
        this.loading = true
        getData('v1/index', (err, responseData) => {
          this.loading = false
          if (err) {
            if (err.toString() == 'Error: notloggedin') {
              this.$store.commit('setuserloggedout')
              this.$router.push({ name: 'Login' })
            }
          } else {
            this.success = true
            this.data = responseData
          }
        })
        getData('v1/map', (err, responseData) => {
          this.loading = false
          if (err) {
            if (err.toString() == 'Error: notloggedin') {
              this.$store.commit('setuserloggedout')
              this.$router.push({ name: 'Login' })
            }
          } else {
            this.showmap = true
            this.mapdata = responseData
          }
        })
      }
    }
  }
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 800px;
}
</style>
