<template>
  <div>
    <div v-if="$store.state.user.email">
      <b-navbar toggleable="lg" type="dark" variant="primary">
        <b-navbar-brand :to="{ name: 'Home' }"><img src="/assets/images/logo.png"></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="{ name: 'Home' }" :class="{active: $route.name==='Home'}" v-b-tooltip.hover :title="$t('navbar.home')"><font-awesome-icon icon="home" class="fa-lg" /></b-nav-item>
            <b-nav-item :to="{ name: 'Customers' }" :class="{active: $route.name==='Customers'}" v-b-tooltip.hover :title="$t('navbar.customers')"><font-awesome-icon icon="users" class="fa-lg" /></b-nav-item>
            <b-nav-item :to="{ name: 'Installations' }" :class="{active: $route.name==='Installations'}" v-b-tooltip.hover :title="$t('navbar.installations')"><font-awesome-icon icon="house-laptop" class="fa-lg" /></b-nav-item>
            <b-nav-item :to="{ name: 'Kiosks' }" :class="{active: $route.name==='Kiosks'}" v-b-tooltip.hover :title="$t('navbar.kiosks')"><font-awesome-icon icon="tablet-android" class="fa-lg" /></b-nav-item>
            <b-nav-item :to="{ name: 'Logout' }" :class="{active: $route.name==='Logout'}" v-b-tooltip.hover :title="$t('navbar.logoff')"><font-awesome-icon icon="user" class="fa-lg" /></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <div v-else>
      <b-navbar toggleable="lg" type="dark" variant="primary">
        <b-navbar-brand :to="{ name: 'Home' }"><img src="/assets/images/logo.png"></b-navbar-brand>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar'
}
</script>
