import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import $ from "jquery";
import * as VueGoogleMaps from 'vue2-google-maps'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faHome, faQuestionCircle, faUsersClass, faUsers, faUser, faFolders, faCalendarCheck, faUserCheck, faUserTag, faAward, faShoppingCart, faFileChartPie, faCogs, faUsersCrown, faUnlock, faSignIn, faSpinner, faPrint, faEdit, faCompress, faInfo, faExpandArrows, faSave, faTrash, faCopy, faBusinessTime, faFileExport, faPlusSquare, faTimes, faCheck, faKey, faCabinetFiling, faMinusCircle, faEye, faHouseLaptop, faTabletAndroid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { BootstrapVue } from 'bootstrap-vue'

Vue.use(BootstrapVue)

import './app.scss'

library.add(faUserSecret, faHome, faQuestionCircle, faUsersClass, faUsers, faUser, faFolders, faCalendarCheck, faUserCheck, faUserTag, faAward, faShoppingCart, faFileChartPie, faCogs, faUsersCrown, faUnlock, faSignIn, faSpinner, faPrint, faEdit, faCompress, faInfo, faExpandArrows, faSave, faTrash, faCopy, faBusinessTime, faFileExport, faPlusSquare, faTimes, faCheck, faKey, faCabinetFiling, faMinusCircle, faEye, faHouseLaptop, faTabletAndroid )

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA8RMX8iZYoTpMQ0tFe-QfdMmn99tJheWE',
    libraries: 'places'
  },
})

Vue.prototype.$jquery = $;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

// load data from api's into store

$.ajax({

  url: '/api/v1/user.php',
  type: 'get',
  dataType: 'JSON',
  success: function(response){

    if (response.success == true) {

      store.commit('setuserdata', response.data.user)

    } else if ("error" in response) {

      if (response.error == 'notloggedin') {

        router.push({ name: 'Login' })
        store.commit('setuserloggedout')

      }

    } else {

      //cb(new Error('Comms error.'))

    }

  },
  error: function() {
    //cb(new Error('Comms error.'))
  }

});
