<template>
  <div class="login">
    <br>
    <b-row>
      <b-col cols="3"></b-col>
      <b-col cols="6">
        <b-button block variant="primary" href="https://dashboard.toekomstperspectief.be/account/login.php">{{ $t('account.login.title') }}</b-button>
      </b-col>
      <b-col cols="3"></b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    name: 'Login'
  }
</script>
