<template>
  <div class="text-center text-primary">
    <font-awesome-icon icon="spinner" class="fa-lg fa-10x fa-spin" />
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>
