<template>
  <div class="customers">
    <br>
    <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    <div v-if="success">
      <b-card :header-tag="header" class="mt-4" no-body>
        <template #header>
          <h6 class="mb-0">{{ $t('customers.title') }}</h6>
        </template>
        <b-card-text>
          <b-form-group label-cols-sm="9" class="mb-2 mt-2 mr-2">
            <b-input-group>
              <b-form-input id="filter-input" v-model="filter" type="search" :placeholder="$t('search.placeholder')"></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" :disabled="!filter" @click="filter = ''">{{ $t('search.clear') }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-table :items="data.table" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"></b-pagination>
        </b-card-text>
      </b-card>
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
  import Loading from '@/components/Loading.vue'
  import { getData } from '@/api'

  export default {
    name: 'Customers',
    components: {
      Loading
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: this.$t('customers.breadcrumbs'),
            active: true
          }
        ],
        data: {},
        success: null,
        show: true,
        fields: [
          { key: 'name', label: this.$t('customers.table.name'), sortable: true },
          { key: 'type', label: this.$t('customers.table.type'), sortable: true },
          { key: 'adress', label: this.$t('customers.table.adress'), sortable: true },
          { key: 'numberoffinstalls', label: this.$t('customers.table.numberoffinstalls'), sortable: true },
          { key: 'numberoffstudents', label: this.$t('customers.table.numberoffstudents'), sortable: true },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 15,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
      }
    },
    created () {
      this.onLoad()
    },
    methods: {
      onLoad () {
        this.error = null
        this.loading = true
        getData('v1/customers', (err, responseData) => {
          this.loading = false
          if (err) {
            if (err.toString() == 'Error: notloggedin') {
              this.$store.commit('setuserloggedout')
              this.$router.push({ name: 'Login' })
            }
          } else {
            this.success = true
            this.data = responseData
            this.totalRows = this.data.table.length
          }
        })
      }
    }
  }
</script>
