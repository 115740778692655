<template>
  <div class="installations">
    <br>
    <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    <div v-if="success">
      <div v-if="$store.state.user.email == 'floris@toekomstperspectief.be'">
        <b-card :header="$t('installations.new.title')" header-tag="header">
          {{ newinstallationinfo }}
          <b-form-group :label="$t('installations.new.url')">
            <b-form-input v-model="newitem.url" type="text" required></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('installations.new.customer')">
            <b-form-select v-model="newitem.customer" :options="customers"></b-form-select>
          </b-form-group>
          <b-button variant="primary" @click="newinstallation()"><font-awesome-icon icon="save" class="fa-lg" /></b-button>
        </b-card>
      </div>
      <b-card :header-tag="header" class="mt-4" no-body>
        <template #header>
          <h6 class="mb-0">{{ $t('installations.title') }}</h6>
        </template>
        <b-card-text>
          <b-form-group label-cols-sm="9" class="mb-2 mt-2 mr-2">
            <b-input-group>
              <b-form-input id="filter-input" v-model="filter" type="search" :placeholder="$t('search.placeholder')"></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" :disabled="!filter" @click="filter = ''">{{ $t('search.clear') }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-table :items="data.table" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
            <template #cell(id)="row">
              <b-button variant="primary" @click="openmodal(row.item, row.index, $event.target)">
                <font-awesome-icon icon="eye" class="fa-lg" />
              </b-button> &nbsp; <b-button variant="primary" @click="supportlogin(row.item.id)">
                <font-awesome-icon icon="sign-in" class="fa-lg" />
              </b-button>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center"></b-pagination>
        </b-card-text>
      </b-card>
      <b-modal :id="infoModal.id" :title="$t('installations.title')" ok-only>
        <pre>{{ infoModal.content }}</pre>
      </b-modal>
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
  import Loading from '@/components/Loading.vue'
  import { getData, postData } from '@/api'

  export default {
    name: 'Installations',
    components: {
      Loading
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: this.$t('installations.breadcrumbs'),
            active: true
          }
        ],
        data: {},
        success: null,
        show: true,
        fields: [
          { key: 'id', label: '' },
          { key: 'url', label: this.$t('installations.table.url'), sortable: true },
          { key: 'customer', label: this.$t('installations.table.customer'), sortable: true },
          { key: 'numberoffstudents', label: this.$t('installations.table.numberoffstudents'), sortable: true },
          { key: 'numberoffteachers', label: this.$t('installations.table.numberoffteachers'), sortable: true }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 15,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        newitem: {
          url: "https://<platform>.toekomstperspectief.be/",
          customer: null
        },
        infoModal: {
          id: 'info-modal',
          content: ''
        },
        customers: [],
        newinstallationinfo: ""
      }
    },
    created () {
      this.onLoad()
    },
    methods: {
      onLoad () {
        this.error = null
        this.loading = true
        getData('v1/installations', (err, responseData) => {
          this.loading = false
          if (err) {
            if (err.toString() == 'Error: notloggedin') {
              this.$store.commit('setuserloggedout')
              this.$router.push({ name: 'Login' })
            }
          } else {
            this.success = true
            this.data = responseData
            this.totalRows = this.data.table.length
          }
        })
        getData('v1/customers', (err, responseData) => {
          this.loading = false
          if (err) {
            if (err.toString() == 'Error: notloggedin') {
              this.$store.commit('setuserloggedout')
              this.$router.push({ name: 'Login' })
            }
          } else {
            this.success = true
            for (const property in responseData.table) {
              this.customers.push({ value: responseData.table[property].id, text: responseData.table[property].name })
            }
          }
        })
      },
      openmodal(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      newinstallation() {
        var sendData = {};
        sendData.url = this.newitem.url
        sendData.customer = this.newitem.customer
        postData('v1/addinstallation', sendData, (err, responseData) => {
          if (err) {
            if (err.toString() == 'Error: notloggedin') {
              if (this.$route.name != 'Login') {
                this.$store.commit('setuserloggedout')
                this.$router.push({ name: 'Login' })
              }
            }
          } else {
            this.$root.$bvToast.toast(this.$t('installations.new.success'), {
              title: this.$t('installations.new.successtitle'),
              autoHideDelay: 5000,
            })
            this.newitem.url = "https://<platform>.toekomstperspectief.be/"
            this.newitem.customer = null
            this.newinstallationinfo = this.$t('installations.new.info', { apikey: responseData.apikey, badgeprefix: responseData.badgeprefix })
            this.onLoad()
          }
        })
      },
      supportlogin(id) {
        var sendData = {};
        sendData.id = id
        postData('v1/supportlogin', sendData, (err, responseData) => {
          if (err) {
            if (err.toString() == 'Error: notloggedin') {
              if (this.$route.name != 'Login') {
                this.$store.commit('setuserloggedout')
                this.$router.push({ name: 'Login' })
              }
            }
          } else {
            window.open(responseData.url, '_blank').focus();
          }
        })
      }
    }
  }
</script>
